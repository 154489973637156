import React from 'react';
import * as styles from './FancyTitle.module.scss';

const FancyTitle = ({ text }) => {
  return (
    <span data-text={text} className={styles.main}>
      {text}
    </span>
  );
};

export default FancyTitle;
