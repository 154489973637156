import React from 'react';

const Stamp = () => {
  return (
    <svg
      width="217"
      height="216"
      viewBox="0 0 217 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M168.632 90.9664C162.897 71.1589 147.737 55.4586 128.037 49.0537C127.842 49.0014 127.605 49.1048 127.514 49.2889C127.451 49.5225 127.565 49.72 127.75 49.8112C147.177 56.1429 162.155 71.5858 167.786 91.157C177.119 123.608 158.286 157.523 125.798 166.838C111.937 170.8 97.0796 169.739 83.9262 163.878C83.7415 163.787 83.4656 163.88 83.4135 164.075C83.3223 164.259 83.4158 164.534 83.611 164.586C86.5975 165.929 89.6571 166.999 92.7793 167.836C103.629 170.743 115.104 170.689 126.022 167.566C159.019 158.22 178.105 123.831 168.632 90.9664Z"
          fill="#B1D9CB"
        />
        <path
          d="M78.3667 54.4326C78.2523 54.2351 78.0182 54.1723 77.8204 54.2862C66.011 61.0498 56.6169 71.7561 51.4348 84.3834C46.0599 97.418 45.3033 111.481 49.1891 125.079C50.4983 129.559 52.2603 133.911 54.5273 137.939C54.5845 138.037 54.6912 138.108 54.7692 138.129C54.8473 138.15 54.9748 138.142 55.0737 138.085C55.2715 137.971 55.3341 137.738 55.2197 137.54C53.0308 133.533 51.2479 129.259 49.9673 124.828C42.1748 97.7118 53.7612 68.9886 78.2207 54.9775C78.4185 54.8636 78.4811 54.6301 78.3667 54.4326Z"
          fill="#B1D9CB"
        />
        <path
          d="M191.082 139.405C190.858 139.303 190.66 139.417 190.559 139.64C180.569 165.371 159.604 184.448 133.064 192.02C105.391 199.914 75.5945 193.724 53.2931 175.484C53.1188 175.354 52.8638 175.369 52.7336 175.543C52.6034 175.717 52.6189 175.971 52.7932 176.101C62.7058 184.222 74.0482 189.972 86.0684 193.193C101.367 197.292 117.682 197.284 133.305 192.835C146.802 188.985 158.817 182.235 169.053 172.755C178.793 163.727 186.472 152.394 191.308 139.966C191.42 139.704 191.316 139.468 191.082 139.405Z"
          fill="#B1D9CB"
        />
        <path
          d="M84.7079 24.0534C128.014 11.6717 173.329 33.7835 190.097 75.4854C190.172 75.6724 190.435 75.7845 190.622 75.7096C190.81 75.6347 190.922 75.3727 190.847 75.1856C182.736 55.0336 167.469 38.6374 147.891 28.9695C128.156 19.2599 105.651 17.2343 84.4949 23.2872C66.2434 28.4916 50.4716 39.1157 38.9312 54.0857C27.6018 68.7367 21.254 86.1826 20.5836 104.607C20.5705 104.812 20.6953 104.971 20.8904 105.023C20.9295 105.034 20.9295 105.034 20.9685 105.044C21.1741 105.058 21.3927 104.866 21.4059 104.661C22.8018 66.8249 48.2439 34.4726 84.7079 24.0534Z"
          fill="#B1D9CB"
        />
        <path
          d="M48.5718 75.3986C48.9157 74.7398 49.0825 74.1171 49.0542 73.4421C49.065 72.7776 48.8883 72.188 48.5243 71.6733C48.2097 71.1301 47.7363 70.7113 47.1146 70.3778C46.4929 70.0444 45.819 69.9055 45.1606 70.0211C44.5126 70.0978 43.8905 70.39 43.3829 70.8796C43.3314 69.823 42.7567 69.0016 41.6979 68.4259C40.7848 67.9309 39.8845 67.8566 39.0075 68.1638C38.1304 68.4711 37.4535 69.124 36.9378 70.1121L34.7315 74.4432L45.9932 80.339L48.5718 75.3986ZM41.0814 73.2246L40.4849 74.358L37.9199 73.0032L38.5164 71.8699C39.0529 70.804 39.7635 70.4938 40.648 70.9394C41.0564 71.1323 41.3061 71.4494 41.3683 71.8415C41.4592 72.283 41.3445 72.7111 41.0814 73.2246ZM46.2534 75.1528L45.334 76.867L42.4386 75.3403L43.358 73.6261C43.6211 73.1126 43.97 72.7472 44.3838 72.6078C44.7976 72.4684 45.2087 72.4952 45.6458 72.7374C46.1218 72.9901 46.4105 73.3177 46.5118 73.7203C46.6026 74.1618 46.5165 74.6393 46.2534 75.1528Z"
          fill="#B1D9CB"
        />
        <path
          d="M42.7651 60.7529L41.2246 62.9709L51.6874 70.2105L53.2279 67.9925L42.7651 60.7529Z"
          fill="#B1D9CB"
        />
        <path
          d="M58.3022 58.4419L54.9773 62.2647L47.0338 55.3809L45.2269 57.4412L54.8428 65.7326L59.9642 59.8884L58.3022 58.4419Z"
          fill="#B1D9CB"
        />
        <path
          d="M58.4239 44.25L52.5691 49.3972L60.9691 58.9479L63.0465 57.1269L59.5435 53.1848L62.8236 50.3095L61.3699 48.7102L58.0897 51.5856L56.0404 49.2428L59.8673 45.8882L58.4239 44.25Z"
          fill="#B1D9CB"
        />
        <path
          d="M64.2979 47.6176C65.0051 48.7249 65.8243 49.5701 66.7556 50.1533C67.6869 50.7366 68.7016 51.0085 69.7502 50.9975C70.7989 50.9864 71.8529 50.6432 72.8629 49.9961C73.9119 49.3594 74.6695 48.5613 75.1254 47.6406C75.5812 46.7199 75.8133 45.6975 75.6838 44.62C75.5542 43.5424 75.1801 42.441 74.4833 41.2949C73.7761 40.1877 72.9568 39.3424 72.0256 38.7592C71.0943 38.176 70.0796 37.9041 69.0309 37.9151C67.9823 37.9261 66.9282 38.2694 65.9183 38.9165C64.8693 39.5531 64.1116 40.3512 63.6558 41.2719C63.1609 42.1822 62.9678 43.215 63.0974 44.2926C63.1775 45.3986 63.5906 46.5104 64.2979 47.6176ZM66.031 42.2421C66.2655 41.6792 66.6353 41.236 67.1793 40.9229C67.7337 40.5709 68.314 40.4344 68.8917 40.464C69.4694 40.4937 70.0729 40.7388 70.6062 41.0903C71.1681 41.4911 71.6492 42.0372 72.0886 42.7389C72.528 43.4407 72.8008 44.1395 72.9176 44.7965C73.0344 45.4535 72.9456 46.0971 72.7215 46.6211C72.487 47.1839 72.1173 47.6272 71.5733 47.9402C71.0189 48.2923 70.4385 48.4288 69.8608 48.3991C69.2832 48.3695 68.6796 48.1243 68.1464 47.7729C67.5845 47.372 67.1034 46.826 66.664 46.1242C66.2246 45.4225 65.9517 44.7237 65.8349 44.0667C65.7182 43.4097 65.7966 42.805 66.031 42.2421Z"
          fill="#B1D9CB"
        />
        <path
          d="M80.4557 40.3923L81.718 39.8962L85.7052 43.5926L88.654 42.4222L84.2375 38.6108C84.8831 38.0746 85.305 37.4368 85.5031 36.6973C85.6622 35.9474 85.5872 35.1347 85.2676 34.2982C84.8258 33.137 84.1028 32.401 83.148 32.0617C82.1931 31.7225 81.0456 31.7904 79.7833 32.2864L75.0594 34.1493L79.758 45.9619L82.2826 44.9698L80.4557 40.3923ZM78.3844 35.1653L80.1595 34.473C80.8101 34.2302 81.3306 34.161 81.7494 34.315C82.1683 34.4689 82.457 34.7966 82.6831 35.3577C82.9092 35.9189 82.9115 36.3784 82.7292 36.7466C82.5469 37.1149 82.1199 37.4594 81.4693 37.7022L79.6943 38.3946L78.3844 35.1653Z"
          fill="#B1D9CB"
        />
        <path
          d="M91.7019 30.8915C92.1937 30.773 92.6829 30.8206 93.1018 30.9746C93.5206 31.1285 93.8302 31.3783 94.0199 31.7629L96.0738 30.1858C95.7125 29.505 95.0934 29.0054 94.1958 28.7649C93.3086 28.4855 92.3094 28.468 91.1982 28.7125C90.3524 28.903 89.6418 29.2132 89.0561 29.682C88.4704 30.1508 88.0303 30.7003 87.775 31.341C87.5197 31.9817 87.4699 32.6358 87.6543 33.3526C87.8646 34.285 88.304 34.9867 88.9725 35.4578C89.641 35.929 90.5776 36.1799 91.772 36.2497L93.0158 36.291C93.6715 36.3415 94.1503 36.4281 94.4806 36.6C94.811 36.772 94.9826 37.0682 95.0839 37.4708C95.1852 37.8734 95.0809 38.2626 94.7919 38.5606C94.4925 38.8975 94.0578 39.1147 93.5269 39.2227C92.2387 39.5033 91.2163 39.1042 90.4986 38.0359L88.6476 39.7925C89.1546 40.554 89.8517 41.0745 90.817 41.3749C91.7822 41.6752 92.8309 41.6642 94.0306 41.4017C94.9649 41.1932 95.7249 40.8545 96.3496 40.3962C96.9744 39.9379 97.3754 39.378 97.6307 38.7372C97.8861 38.0965 97.9073 37.393 97.7333 36.6373C97.3023 34.8115 95.8586 33.799 93.332 33.706L92.0882 33.6647C91.5105 33.6351 91.1202 33.5305 90.8289 33.369C90.5375 33.2075 90.3659 32.9112 90.2933 32.558C90.192 32.1555 90.2858 31.8052 90.5358 31.4968C90.7858 31.1883 91.171 30.9995 91.7019 30.8915Z"
          fill="#B1D9CB"
        />
        <path
          d="M102.016 27.2398L99.2827 27.5131L100.569 40.1449L103.302 39.8716L102.016 27.2398Z"
          fill="#B1D9CB"
        />
        <path
          d="M112.851 27.0477L108.881 32.0323L108.993 26.9314L106.289 26.8744L106.025 39.5681L108.728 39.6251L108.801 35.7651L109.4 35.0913L112.382 39.7281L115.84 39.7787L111.058 33.1163L116.104 27.085L112.851 27.0477Z"
          fill="#B1D9CB"
        />
        <path
          d="M126.966 35.2515C127.524 34.7333 127.849 33.9863 128.05 33.0807C128.295 31.8533 128.056 30.8716 127.443 30.0397C126.819 29.2468 125.838 28.692 124.48 28.4532L119.497 27.5352L117.198 40.0175L119.865 40.5235L120.737 35.7096L122.095 35.9484L123.539 41.1742L126.646 41.7564L124.874 36.1925C125.688 36.1187 126.409 35.7696 126.966 35.2515ZM124.56 33.7725C124.165 34.0002 123.654 34.0304 122.981 33.8915L121.105 33.5557L121.718 30.1743L123.594 30.5101C124.268 30.649 124.754 30.8628 125.043 31.1904C125.332 31.518 125.412 31.9984 125.305 32.5538C125.188 33.148 124.956 33.5448 124.56 33.7725Z"
          fill="#B1D9CB"
        />
        <path
          d="M131.826 30.2398L128.038 42.3544L130.622 43.1616L134.41 31.047L131.826 30.2398Z"
          fill="#B1D9CB"
        />
        <path
          d="M140.801 47.4681L142.367 48.1798L147.746 36.6898L145.52 35.6342L142.271 42.6061L140.199 33.1659L138.605 32.4049L133.215 43.9338L135.491 44.9608L138.638 38.2121L140.801 47.4681Z"
          fill="#B1D9CB"
        />
        <path
          d="M151.732 44.1399C152.364 43.8088 152.994 43.6438 153.621 43.645C154.287 43.6566 154.862 43.8524 155.385 44.2428C155.908 44.6332 156.264 45.0206 156.483 45.4546C156.701 45.8885 156.802 46.2911 156.787 46.6623C156.771 47.0335 156.726 47.3553 156.664 47.5888L159.086 48.071C159.446 47.0411 159.423 46.0338 159.017 45.0492C158.612 44.0646 157.892 43.1624 156.807 42.3712C155.829 41.6503 154.767 41.2407 153.672 41.114C152.577 40.9874 151.515 41.2034 150.507 41.6844C149.5 42.1654 148.625 42.9321 147.855 43.9353C147.084 44.9385 146.664 46.0358 146.539 47.1284C146.403 48.26 146.543 49.2987 146.977 50.3327C147.422 51.3278 148.152 52.191 149.13 52.9119C150.535 53.914 151.979 54.3009 153.423 54.0622C154.868 53.8235 156.177 52.8395 157.37 51.1985C157.489 51.0637 157.64 50.8122 157.851 50.4933L152.698 46.7766L151.436 48.5239L154.38 50.6477C153.859 51.3425 153.305 51.6945 152.628 51.7218C151.952 51.749 151.26 51.5219 150.524 50.9909C150.001 50.6006 149.626 50.1248 149.411 49.5247C149.195 48.9247 149.156 48.2886 149.266 47.5671C149.365 46.8846 149.67 46.2154 150.131 45.588C150.592 44.9607 151.138 44.4814 151.732 44.1399Z"
          fill="#B1D9CB"
        />
        <path
          d="M158.122 60.4105L159.922 62.4361L163.858 58.9857L166.745 62.262L168.385 60.8243L165.498 57.548L167.844 55.5068L171.211 59.3292L172.851 57.8915L167.694 52.0046L158.122 60.4105Z"
          fill="#B1D9CB"
        />
        <path
          d="M177.639 64.8473L174.885 60.6055L164.221 67.4673L165.703 69.7416L169.841 67.0963L170.577 68.2529L167.749 72.8762L169.46 75.5454L172.325 70.4731C172.972 71.0221 173.693 71.2986 174.437 71.3312C175.181 71.3637 175.96 71.1134 176.761 70.6191C177.821 69.9435 178.383 69.0931 178.566 68.0992C178.67 67.0844 178.374 66.0039 177.639 64.8473ZM175.212 68.285C174.707 68.6085 174.264 68.6985 173.835 68.5835C173.406 68.4685 173.039 68.1199 172.657 67.517L171.624 65.9055L174.506 64.0496L175.538 65.6611C175.931 66.2251 176.089 66.7264 176.014 67.165C175.987 67.5751 175.717 67.9614 175.212 68.285Z"
          fill="#B1D9CB"
        />
        <path
          d="M174.042 86.0754L183.901 76.912L183.022 74.8829L169.525 75.7713L170.64 78.3227L172.888 78.0491L174.598 81.9696L172.87 83.4253L174.042 86.0754ZM175.175 77.786L180.359 77.1727L176.366 80.5244L175.175 77.786Z"
          fill="#B1D9CB"
        />
        <path
          d="M176.181 94.9498C176.425 96.2248 176.888 97.3082 177.569 98.1999C178.261 99.0527 179.143 99.6644 180.176 100.025C181.209 100.385 182.364 100.444 183.592 100.231C184.86 100.028 185.906 99.5577 186.76 98.8688C187.614 98.1799 188.226 97.301 188.546 96.2606C188.906 95.2307 188.974 94.0393 188.73 92.7642L188.084 89.0871L175.574 91.2831L176.181 94.9498ZM186.389 92.1368L186.523 92.8821C186.739 94.1078 186.556 95.1017 185.916 95.9312C185.314 96.7711 184.346 97.2625 183.107 97.5146C181.84 97.7173 180.794 97.5622 179.901 96.9894C179.048 96.4271 178.494 95.5278 178.269 94.3411L178.134 93.5958L186.389 92.1368Z"
          fill="#B1D9CB"
        />
        <path
          d="M177.428 105.754L184.904 105.797L177.401 111.629L177.431 113.389L190.14 113.415L190.131 110.952L182.439 110.935L190.158 105.077L190.156 103.366L177.457 103.301L177.428 105.754Z"
          fill="#B1D9CB"
        />
        <path
          d="M176.179 121.187C176.069 121.909 176.097 122.584 176.313 123.184C176.518 123.823 176.843 124.327 177.327 124.707C177.8 125.126 178.404 125.371 179.039 125.499C179.762 125.61 180.41 125.533 181.022 125.28C181.644 124.988 182.091 124.565 182.464 123.956C182.82 124.969 183.624 125.56 184.826 125.757C185.861 125.951 186.707 125.76 187.441 125.206C188.175 124.652 188.649 123.82 188.824 122.698L189.685 117.923L177.149 115.691L176.179 121.187ZM187.051 120.263L186.845 121.501C186.649 122.699 186.063 123.168 185.106 122.995C184.666 122.919 184.346 122.708 184.146 122.363C183.945 122.017 183.904 121.547 184.011 120.992L184.217 119.754L187.051 120.263ZM182.058 119.384L181.713 121.294C181.596 121.888 181.403 122.295 181.036 122.572C180.669 122.849 180.276 122.911 179.797 122.824C179.279 122.727 178.881 122.495 178.681 122.15C178.442 121.794 178.401 121.324 178.518 120.729L178.863 118.819L182.058 119.384Z"
          fill="#B1D9CB"
        />
        <path
          d="M43.2038 141.328C43.0948 140.799 43.0925 140.339 43.1759 140.028C43.2593 139.716 43.4884 139.486 43.8242 139.325C44.2094 139.137 44.5425 139.142 44.9119 139.325C45.2813 139.507 45.57 139.835 45.8169 140.318C46.0639 140.802 46.1547 141.243 46.1181 141.692C46.0814 142.141 45.8991 142.509 45.5815 142.758L47.6233 144.348C48.1805 143.83 48.5061 143.083 48.5404 142.174C48.5851 141.227 48.3358 140.284 47.8133 139.268C47.4338 138.499 46.9241 137.903 46.3336 137.453C45.7431 137.003 45.1005 136.747 44.4162 136.648C43.7215 136.586 43.0631 136.702 42.4306 137.033C41.5613 137.468 41.0223 138.074 40.7357 138.832C40.4491 139.589 40.4329 140.586 40.6691 141.734L40.9235 142.97C41.0507 143.588 41.0817 144.097 40.9878 144.447C40.894 144.797 40.6544 145.067 40.3187 145.227C39.9335 145.416 39.5614 145.4 39.1919 145.217C38.8225 145.035 38.4662 144.647 38.2193 144.164C37.611 143 37.7364 141.907 38.6243 140.936L36.4313 139.597C35.7935 140.26 35.486 141.096 35.4308 142.082C35.3757 143.068 35.6432 144.1 36.1839 145.204C36.631 146.033 37.1199 146.706 37.7494 147.167C38.3294 147.656 39.011 147.922 39.7058 147.983C40.4005 148.044 41.1083 147.9 41.7799 147.579C43.4691 146.739 44.045 145.058 43.5258 142.624L43.2038 141.328Z"
          fill="#B1D9CB"
        />
        <path
          d="M53.7965 152.843L55.551 151.603L49.9942 143.774L48.2007 145.003L50.2029 147.834L41.6283 153.921L43.2093 156.138L51.7839 150.051L53.7965 152.843Z"
          fill="#B1D9CB"
        />
        <path
          d="M58.2012 154.357L45.8642 159.811L47.7914 161.83L49.8268 160.79L52.7656 163.872L51.6457 165.866L53.6405 167.944L59.7434 155.938L58.2012 154.357ZM53.8647 161.955L51.8127 159.779L56.4952 157.446L53.8647 161.955Z"
          fill="#B1D9CB"
        />
        <path
          d="M66.9204 170.209C67.6491 169.987 68.2843 169.49 68.8545 168.767C69.6149 167.802 69.886 166.79 69.6966 165.78C69.5071 164.77 68.8753 163.85 67.762 163.009L63.7355 159.928L56.0176 169.999L58.1661 171.659L61.1293 167.781L62.2036 168.611L61.0793 173.9L63.5764 175.82L64.5708 170.08C65.4475 170.398 66.1917 170.431 66.9204 170.209ZM64.0388 167.226L62.5093 166.065L64.621 163.336L66.1505 164.496C66.7019 164.936 67.0088 165.352 67.1101 165.755C67.2113 166.157 67.0472 166.614 66.706 167.106C66.3259 167.588 65.9693 167.827 65.5087 167.828C65.0481 167.83 64.5616 167.616 64.0388 167.226Z"
          fill="#B1D9CB"
        />
        <path
          d="M70.5195 179.725L75.6166 170.537L78.6368 172.222L79.6865 170.334L71.2761 165.661L70.2265 167.549L73.2467 169.234L68.1106 178.412L70.5195 179.725Z"
          fill="#B1D9CB"
        />
        <path
          d="M83.5277 184.879C84.1756 184.802 84.7483 184.538 85.2246 184.165C85.7115 183.754 86.0916 183.271 86.3079 182.62C86.5737 181.941 86.5844 181.276 86.4182 180.647C86.252 180.019 85.8984 179.465 85.3574 178.987C86.4088 178.81 87.1351 178.128 87.565 176.992C87.9142 176.001 87.8991 175.121 87.491 174.302C87.0828 173.484 86.3676 172.875 85.2566 172.494L80.6881 170.811L76.2428 182.718L81.4539 184.657C82.2344 184.866 82.9187 184.966 83.5277 184.879ZM82.6096 173.787L83.7881 174.228C84.9277 174.658 85.2994 175.3 84.9711 176.213C84.8174 176.631 84.5284 176.929 84.164 177.04C83.7892 177.19 83.3391 177.153 82.7745 176.918L81.596 176.477L82.6096 173.787ZM81.5019 182.292L79.6808 181.595L80.8377 178.526L82.6589 179.223C83.2338 179.419 83.611 179.728 83.8007 180.113C83.9905 180.498 84.0033 180.918 83.8391 181.375C83.6645 181.87 83.3651 182.207 82.9513 182.347C82.5661 182.535 82.0769 182.488 81.5019 182.292Z"
          fill="#B1D9CB"
        />
        <path
          d="M99.6704 176.773C98.8875 176.105 97.8859 175.628 96.6942 175.392C95.5026 175.156 94.433 175.245 93.418 175.599C92.442 175.963 91.609 176.574 90.9476 177.481C90.2966 178.35 89.8381 179.436 89.6111 180.752C89.384 182.068 89.4042 183.241 89.7107 184.283C90.0173 185.324 90.571 186.224 91.3539 186.892C92.1368 187.561 93.1384 188.038 94.3301 188.274C95.5218 188.51 96.5913 188.421 97.6063 188.067C98.5823 187.703 99.4153 187.092 100.077 186.184C100.728 185.316 101.186 184.229 101.413 182.913C101.64 181.598 101.61 180.463 101.314 179.383C101.007 178.341 100.453 177.442 99.6704 176.773ZM97.8996 184.475C97.5376 185.045 97.0508 185.457 96.5276 185.692C95.994 185.966 95.3955 186.015 94.7607 185.886C94.1258 185.758 93.6107 185.495 93.1764 185.086C92.7525 184.639 92.4769 184.106 92.3211 183.439C92.1653 182.771 92.1579 182.019 92.3275 181.23C92.4684 180.391 92.7342 179.712 93.0962 179.141C93.4581 178.571 93.945 178.159 94.4681 177.924C95.0017 177.65 95.6002 177.602 96.2351 177.73C96.8699 177.858 97.385 178.121 97.8194 178.53C98.2537 178.938 98.5188 179.51 98.6746 180.177C98.8304 180.845 98.8378 181.598 98.6683 182.386C98.5273 183.225 98.2616 183.904 97.8996 184.475Z"
          fill="#B1D9CB"
        />
        <path
          d="M114.371 189.347L114.302 176.648L111.833 176.653L111.865 184.337L105.962 176.707L104.208 176.696L104.277 189.396L106.747 189.39L106.701 181.911L112.656 189.347L114.371 189.347Z"
          fill="#B1D9CB"
        />
        <path
          d="M118.851 186.835C119.361 187.43 120.04 187.863 120.849 188.121C121.658 188.38 122.529 188.405 123.531 188.256C124.533 188.107 125.343 187.74 126.056 187.264C126.769 186.788 127.23 186.16 127.517 185.403C127.803 184.645 127.856 183.825 127.724 182.914L126.317 174.736L123.694 175.16L125.09 183.376C125.207 184.033 125.1 184.588 124.73 185.031C124.399 185.485 123.866 185.759 123.158 185.903C122.46 186.008 121.904 185.901 121.399 185.599C120.894 185.297 120.619 184.764 120.541 184.118L119.145 175.901L116.473 176.353L117.879 184.531C118.011 185.442 118.352 186.201 118.851 186.835Z"
          fill="#B1D9CB"
        />
        <path
          d="M135.506 182.746C134.265 183.165 133.201 182.921 132.345 181.899L130.726 183.885C131.293 184.579 132.089 185.043 133.085 185.226C134.082 185.41 135.123 185.272 136.276 184.871C137.153 184.564 137.895 184.137 138.452 183.619C139.009 183.101 139.381 182.492 139.519 181.819C139.697 181.158 139.64 180.433 139.377 179.696C138.769 177.906 137.185 177.106 134.664 177.306L133.428 177.392C132.868 177.451 132.428 177.375 132.127 177.252C131.825 177.13 131.643 176.872 131.492 176.498C131.341 176.124 131.396 175.763 131.617 175.406C131.839 175.048 132.195 174.81 132.708 174.613C133.21 174.456 133.681 174.415 134.111 174.53C134.54 174.645 134.899 174.867 135.099 175.212L136.922 173.406C136.461 172.782 135.782 172.35 134.864 172.187C133.945 172.025 132.954 172.135 131.89 172.517C131.062 172.796 130.409 173.204 129.89 173.733C129.372 174.262 129 174.871 128.823 175.533C128.645 176.194 128.674 176.869 128.947 177.568C129.245 178.482 129.784 179.127 130.522 179.492C131.251 179.896 132.219 180.03 133.405 179.973L134.641 179.887C135.279 179.849 135.768 179.897 136.109 180.03C136.45 180.163 136.671 180.43 136.822 180.805C136.962 181.218 136.907 181.578 136.647 181.926C136.464 182.294 136.058 182.561 135.506 182.746Z"
          fill="#B1D9CB"
        />
        <path
          d="M154.343 168.647C154.46 168.678 154.588 168.671 154.697 168.575C164.438 161.257 172.356 151.532 177.571 140.498C177.662 140.314 177.569 140.039 177.384 139.948C177.199 139.857 176.923 139.949 176.832 140.134C171.687 151.061 163.84 160.68 154.208 167.902C154.049 168.026 153.976 168.298 154.14 168.467C154.197 168.566 154.265 168.626 154.343 168.647Z"
          fill="#B1D9CB"
        />
        <path
          d="M34.966 94.487C35.3861 92.1385 35.9338 89.7824 36.5881 87.4966C36.6507 87.2631 36.5363 87.0656 36.3021 87.0028C36.107 86.9505 35.8701 87.0539 35.8076 87.2874C35.1533 89.5732 34.5952 91.9683 34.1646 94.3557C31.9831 106.244 32.6846 118.612 36.2228 130.072C36.2696 130.21 36.3658 130.319 36.4829 130.35C36.5609 130.371 36.639 130.392 36.6989 130.325C36.9253 130.26 37.0269 130.037 36.962 129.811C33.4732 118.323 32.7741 106.414 34.966 94.487Z"
          fill="#B1D9CB"
        />
        <path
          d="M67.8635 112.521C69.4317 114.317 71.7235 115.599 74.6115 116.373C76.9141 116.99 79.0686 117.067 81.1037 116.652C83.0997 116.228 84.8593 115.281 86.2729 113.908C87.697 112.496 88.705 110.764 89.2785 108.623C90.165 105.315 89.966 102.467 88.7104 100.128C87.4548 97.7892 85.3244 96.2172 82.3194 95.412C80.329 94.8787 78.3955 95.0697 76.5579 95.9956L79.7 88.3279L91.6032 91.5173L93.0945 85.9518L75.3373 81.1938L68.532 97.8496L73.9947 101.191C74.7992 100.53 75.5696 100.153 76.267 100.047C76.9749 99.9033 77.8647 100.017 78.9185 100.299C80.3625 100.686 81.4081 101.467 82.084 102.691C82.76 103.915 82.905 105.247 82.5087 106.726C82.0812 108.321 81.2583 109.519 80.0895 110.29C78.8817 111.051 77.5753 111.244 76.1704 110.867C74.7264 110.48 73.6104 109.806 72.8329 108.805C72.0163 107.794 71.5746 106.632 71.44 105.261L65.1476 105.953C65.331 108.547 66.2563 110.714 67.8635 112.521Z"
          fill="#B1D9CB"
        />
        <path
          d="M99.5942 91.8231C96.7645 94.11 94.6339 98.0026 93.1844 103.412C91.7348 108.822 91.6336 113.259 92.9408 116.654C94.2479 120.049 96.8281 122.284 100.731 123.33C104.633 124.376 108.024 123.741 110.815 121.443C113.645 119.156 115.775 115.264 117.225 109.854C118.674 104.444 118.776 100.008 117.468 96.6125C116.161 93.2171 113.581 90.9824 109.678 89.9366C105.776 88.8909 102.424 89.5362 99.5942 91.8231ZM111.357 99.4384C111.726 101.498 111.414 104.376 110.434 108.034C109.454 111.693 108.284 114.341 106.935 115.94C105.587 117.54 104.01 118.118 102.292 117.658C100.536 117.187 99.4986 115.908 99.1301 113.849C98.7617 111.79 99.0728 108.911 100.053 105.253C101.033 101.594 102.203 98.9461 103.552 97.347C104.901 95.7478 106.478 95.1693 108.195 95.6294C109.912 96.0895 110.989 97.3791 111.357 99.4384Z"
          fill="#B1D9CB"
        />
        <path
          d="M133.508 97.3227C132.582 96.4072 131.466 95.7328 130.178 95.3877C128.89 95.0426 127.597 95.0298 126.327 95.3986C125.067 95.7285 123.979 96.3547 123.063 97.2771C122.146 98.1996 121.472 99.312 121.128 100.596C120.784 101.881 120.773 103.171 121.144 104.438C121.476 105.695 122.106 106.782 123.031 107.697C123.957 108.613 125.073 109.287 126.361 109.632C127.649 109.977 128.942 109.99 130.212 109.621C131.472 109.291 132.56 108.665 133.476 107.743C134.393 106.82 135.067 105.708 135.412 104.424C135.756 103.139 135.767 101.849 135.395 100.582C135.063 99.3245 134.434 98.2382 133.508 97.3227ZM129.567 104.693C128.974 105.035 128.326 105.111 127.623 104.923C126.921 104.735 126.437 104.355 126.094 103.762C125.75 103.17 125.673 102.523 125.85 101.862C126.038 101.161 126.428 100.64 127.022 100.298C127.615 99.9569 128.253 99.9192 128.916 100.097C129.619 100.285 130.142 100.676 130.495 101.229C130.838 101.822 130.877 102.458 130.689 103.158C130.541 103.869 130.16 104.351 129.567 104.693Z"
          fill="#B1D9CB"
        />
        <path
          d="M143.319 119.557C142.031 119.212 140.738 119.2 139.468 119.568C138.209 119.898 137.121 120.524 136.204 121.447C135.288 122.369 134.613 123.482 134.269 124.766C133.925 126.05 133.914 127.341 134.285 128.608C134.618 129.865 135.247 130.952 136.173 131.867C137.099 132.783 138.215 133.457 139.503 133.802C140.79 134.147 142.084 134.16 143.354 133.791C144.613 133.461 145.701 132.835 146.618 131.913C147.534 130.99 148.209 129.878 148.553 128.593C148.897 127.309 148.908 126.019 148.537 124.751C148.204 123.494 147.575 122.408 146.649 121.493C145.734 120.538 144.607 119.903 143.319 119.557ZM142.708 128.863C142.115 129.204 141.506 129.291 140.764 129.093C140.062 128.905 139.578 128.525 139.235 127.932C138.892 127.34 138.814 126.693 138.991 126.031C139.179 125.331 139.57 124.81 140.163 124.468C140.757 124.127 141.394 124.089 142.058 124.267C142.76 124.455 143.283 124.845 143.636 125.399C143.98 125.991 144.029 126.589 143.831 127.328C143.643 128.029 143.263 128.511 142.708 128.863Z"
          fill="#B1D9CB"
        />
        <path
          d="M115.938 126.987L121.753 128.546L153.781 102.213L147.927 100.644L115.938 126.987Z"
          fill="#B1D9CB"
        />
        <path
          d="M105.1 77.9892L106.504 78.3656L108.913 69.3752L113.178 80.1538L114.076 80.3943L117.319 68.2903L115.914 67.9138L113.505 76.9043L109.318 66.1466L108.343 65.8851L105.1 77.9892Z"
          fill="#B1D9CB"
        />
        <path
          d="M122.349 72.3079C122.857 72.4439 123.328 72.4032 123.773 72.147C124.218 71.8909 124.489 71.5046 124.614 71.0376C124.75 70.5316 124.708 70.0617 124.451 69.6173C124.193 69.1729 123.806 68.9022 123.298 68.7662C122.791 68.6303 122.32 68.6709 121.914 68.9376C121.469 69.1937 121.198 69.58 121.073 70.047C120.937 70.553 120.979 71.0229 121.247 71.4284C121.494 71.9117 121.881 72.1824 122.349 72.3079ZM122.314 69.6288C122.562 69.4865 122.827 69.4325 123.139 69.5162C123.413 69.5894 123.654 69.7793 123.797 70.0262C123.94 70.2731 123.995 70.538 123.911 70.8493C123.838 71.1218 123.648 71.3628 123.401 71.5052C123.154 71.6475 122.888 71.7015 122.576 71.6178C122.303 71.5446 122.061 71.3547 121.918 71.1078C121.775 70.8609 121.721 70.596 121.804 70.2846C121.887 69.9733 122.067 69.7711 122.314 69.6288Z"
          fill="#B1D9CB"
        />
        <path
          d="M120.533 76.7436C118.421 76.5112 117.166 77.1344 116.77 78.6134C116.572 79.3529 116.688 80.0099 117.071 80.6128C117.453 81.2158 118.103 81.5987 119.001 81.8392C119.586 81.996 120.164 82.0257 120.744 81.8892C121.325 81.7527 121.798 81.5459 122.186 81.191C122.071 81.6191 122.095 82.0007 122.266 82.297C122.438 82.5933 122.708 82.8326 123.099 82.9372C123.45 83.0313 123.694 83.0551 123.882 82.9802L124.143 82.0072C123.908 81.9445 123.763 81.8637 123.706 81.7649C123.609 81.6557 123.612 81.4896 123.664 81.295L124.749 77.2473C125.02 76.2354 124.927 75.3345 124.487 74.6327C124.048 73.931 123.262 73.4285 122.13 73.1252C121.311 72.9056 120.606 72.8835 119.976 73.0485C119.346 73.2135 118.831 73.5759 118.44 74.097L119.444 75.0334C120.014 74.3102 120.792 74.0599 121.807 74.3317C122.432 74.4991 122.858 74.7802 123.155 75.2351C123.412 75.6795 123.492 76.1599 123.336 76.7437L123.253 77.055L120.533 76.7436ZM122.716 78.7466C122.497 79.5639 122.096 80.1239 121.531 80.5148C120.937 80.8563 120.289 80.933 119.509 80.7239C119.04 80.5984 118.682 80.377 118.442 80.021C118.193 79.7038 118.13 79.3117 118.235 78.9225C118.36 78.4554 118.61 78.147 118.995 77.9582C119.38 77.7694 119.929 77.7497 120.624 77.8107L122.893 78.0849L122.716 78.7466Z"
          fill="#B1D9CB"
        />
        <path
          d="M78.0168 138.476C78.1621 139.182 78.5053 139.775 78.9682 140.233C79.4208 140.729 80.0035 141.052 80.706 141.241C81.4865 141.45 82.2021 141.433 82.9308 141.211C83.6595 140.989 84.2634 140.609 84.8414 140.013C85.4194 139.417 85.7736 138.719 86.0031 137.863C86.2325 137.007 86.2459 136.176 86.072 135.42C85.898 134.664 85.5367 133.984 85.0165 133.427C84.4964 132.871 83.846 132.488 83.1045 132.289C82.4411 132.111 81.8139 132.11 81.2336 132.247C80.6532 132.383 80.1197 132.657 79.6224 133.108L80.9468 128.165L79.4248 127.757L76.1815 139.861L77.5084 140.217L77.9777 138.466L78.0168 138.476ZM79.679 134.458C80.0487 134.015 80.4938 133.759 81.0065 133.562C81.5088 133.405 82.0579 133.385 82.6043 133.532C83.1506 133.678 83.5773 133.959 83.8945 134.336C84.2508 134.724 84.4587 135.196 84.5678 135.726C84.6768 136.256 84.6374 136.871 84.4706 137.494C84.2203 138.428 83.7776 139.144 83.1034 139.63C82.4396 140.078 81.6927 140.212 80.9122 140.003C80.3658 139.856 79.9392 139.575 79.5439 139.177C79.1485 138.779 78.9406 138.306 78.842 137.738C78.7434 137.169 78.7333 136.582 78.8897 135.998C79.0566 135.376 79.3197 134.862 79.679 134.458Z"
          fill="#B1D9CB"
        />
        <path
          d="M94.8017 136.091C94.2426 135.524 93.5818 135.18 92.7622 134.96C91.9427 134.741 91.188 134.747 90.4307 134.92C89.6734 135.092 89.0201 135.501 88.4915 136.068C87.963 136.636 87.5593 137.362 87.3195 138.257C87.09 139.114 87.0766 139.944 87.2401 140.739C87.4141 141.495 87.7754 142.175 88.345 142.703C88.9042 143.27 89.565 143.614 90.3845 143.834C91.2041 144.054 91.9587 144.047 92.716 143.875C93.4838 143.663 94.1267 143.293 94.6552 142.726C95.1837 142.158 95.5874 141.432 95.8273 140.537C96.0567 139.681 96.0702 138.85 95.9066 138.055C95.7327 137.3 95.3713 136.619 94.8017 136.091ZM93.5446 141.719C93.1749 142.162 92.7688 142.429 92.2665 142.586C91.7642 142.744 91.2646 142.735 90.7573 142.599C90.2109 142.453 89.7738 142.21 89.4566 141.833C89.1003 141.446 88.8819 141.012 88.7833 140.443C88.6847 139.875 88.7137 139.298 88.8805 138.676C89.0474 138.053 89.3209 137.5 89.6802 137.096C90.0499 136.653 90.456 136.386 90.9583 136.229C91.4606 136.071 91.9602 136.08 92.4675 136.216C93.0139 136.363 93.4509 136.605 93.7682 136.982C94.1245 137.369 94.3324 137.842 94.4415 138.372C94.54 138.941 94.5111 139.517 94.3443 140.14C94.1774 140.762 93.9039 141.315 93.5446 141.719Z"
          fill="#B1D9CB"
        />
        <path
          d="M103.685 147.273L105.155 141.785C105.427 140.773 105.372 139.883 104.982 139.152C104.593 138.422 103.924 137.951 102.948 137.69C102.285 137.512 101.697 137.521 101.116 137.658C100.536 137.794 100.081 138.089 99.6718 138.522L100.068 137.043L98.7412 136.687L96.426 145.328L97.9481 145.735L99.2829 140.754C99.4915 139.975 99.8535 139.405 100.408 139.053C100.923 138.69 101.571 138.614 102.235 138.792C102.859 138.959 103.314 139.289 103.572 139.734C103.829 140.178 103.839 140.765 103.662 141.427L102.212 146.836L103.695 147.234L103.685 147.273Z"
          fill="#B1D9CB"
        />
        <path
          d="M107.873 139.134L106.403 144.622C106.132 145.634 106.186 146.525 106.576 147.255C106.966 147.985 107.634 148.456 108.61 148.717C109.273 148.895 109.901 148.896 110.442 148.749C111.022 148.613 111.478 148.318 111.887 147.885L111.501 149.325L112.828 149.681L115.132 141.079L113.61 140.672L112.275 145.653C112.067 146.432 111.705 147.002 111.15 147.354C110.596 147.706 109.987 147.793 109.324 147.616C108.66 147.438 108.244 147.118 107.987 146.673C107.729 146.229 107.719 145.642 107.896 144.981L109.356 139.532L107.873 139.134Z"
          fill="#B1D9CB"
        />
        <path
          d="M119.437 149.992C119.062 150.141 118.602 150.143 118.094 150.007C117.001 149.714 116.375 149.088 116.224 148.088L114.962 148.584C115.118 149.251 115.432 149.795 115.895 150.252C116.397 150.721 117.019 151.054 117.761 151.253C118.697 151.504 119.501 151.469 120.212 151.159C120.922 150.848 121.362 150.299 121.571 149.521C121.738 148.898 121.678 148.34 121.392 147.846C121.117 147.313 120.643 146.894 119.915 146.491L118.817 145.904C118.419 145.673 118.149 145.433 117.967 145.176C117.824 144.929 117.769 144.664 117.852 144.353C117.946 144.003 118.186 143.733 118.522 143.573C118.857 143.412 119.279 143.4 119.747 143.526C120.684 143.777 121.165 144.323 121.23 145.174L122.539 144.816C122.49 144.219 122.253 143.697 121.829 143.249C121.405 142.802 120.812 142.518 120.071 142.319C119.173 142.079 118.408 142.124 117.736 142.445C117.065 142.765 116.635 143.276 116.437 144.015C116.281 144.599 116.34 145.157 116.626 145.651C116.912 146.145 117.375 146.603 118.065 146.996L119.084 147.561C119.521 147.803 119.792 148.043 119.974 148.3C120.117 148.547 120.161 148.851 120.077 149.162C120.023 149.523 119.773 149.831 119.437 149.992Z"
          fill="#B1D9CB"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="176.281"
            height="176.281"
            fill="white"
            transform="translate(46.313) rotate(15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Stamp;
